import bus from "@/core/api/bus"
import logger from "@/core/logger"
import createAttribution from "./api/event/attribution"
import { VIEW_PRODUCT } from "./event"

type ProductIdentifier = string | { productId: string; skuId?: string }

/**
 * Sends an event to Nosto when a recommended product is added to the cart.
 * @param product - The product identifier.
 * @param recoId - The recommendation identifier.
 */
export async function reportAddToCart(product: ProductIdentifier, recoId: string) {
  if (!product || !recoId) {
    logger.info("No product or element info given.")
    return
  }
  const { productId, skuId } = typeof product === "string" ? { productId: product } : product
  await createAttribution()
    .recordAttribution({ type: VIEW_PRODUCT, target: productId, targetFragment: skuId, ref: recoId })
    .done()
  bus.emit("addtocart", {
    productId,
    skuId,
    placementId: recoId
  })
}
