import context from "@/core/context"
import windows from "@/core/windows"
import { deepClone } from "@/utils/deepClone"
import handleResponse from "@/core/api/event/handlers/index"
import bus from "@/core/api/bus"
import visit from "@/core/visit"
import { parseError } from "@/core/ev1/handler"
import externalIdentification from "@/core/visit/external_identification"
import measurePerformance from "./measurePerformance"
import { Coupon, Order } from "./payload"
import { Cart, Product } from "@/core/tagging/types"
import settings from "./settings"
import { getDebugRequest } from "./debug"
import * as extractFromReferer from "./extractFromReferer"
import * as extractFromUrl from "./extractFromUrl"
import { currentAttribution } from "./parameterlessAttribution"
import logger from "@/core/logger"
import {
  CartItem,
  EventRequestMessageV1,
  EventResponseMessage,
  Experiment,
  OrderInfo,
  PageType,
  PushedCustomer,
  RenderMode
} from "@/types"
import { ParseUriResult } from "@/utils/parseuri"
import {
  Event,
  INTERNAL_SEARCH,
  CUSTOM_CAMPAIGN,
  VIEW_PRODUCT,
  isEventType,
  cleanupEvent,
  EventType,
  BOUGHT_PRODUCT,
  EventTuple,
  toEventTuple
} from "./event"
import { TaggingData } from "./tagging/types"
import klaviyoCookie from "@/core/visit/klaviyo_cookie"
import { validateEvent } from "./api/event/validator"
import ev1 from "./ev1"

const REFERENCE_SOURCE = "refSrc"
const X_NOSTO_CUSTOMER_HEADER = "x-nosto-customer"

type AddEventTuple = [type: EventType, target?: string, ref?: string, targetFragment?: string]

export interface RecommendationRequestFlags {
  // skip page view increment for recent Page Load event (default false)
  skipPageViews?: boolean
  // persist events in active session (default true)
  trackEvents?: boolean
  // skip persistence of events in active session (default false)
  skipEvents?: boolean
  // Handles ADD_TO_CART events exclusively and skip recording any other events
  reloadCart?: boolean
}

function toBpEvents(items: CartItem[] | undefined): Event[] {
  return (items || []).map(item => ({
    type: BOUGHT_PRODUCT,
    target: item.product_id,
    targetFragment: item.sku_id
  }))
}

function toVpEvents(products: Product[], ref?: string): Event[] {
  return products.map(p => ({
    type: VIEW_PRODUCT,
    target: p.product_id,
    ref,
    targetFragment: p.selected_sku_id
  }))
}

function toEventTuples(events: Event[]): EventTuple[] {
  return events.map(toEventTuple)
}

function validateEvents(events: Event[]): Event[] {
  return events.filter(validateEvent)
}

function populateFromData(request: RequestBuilder, data: TaggingData, unwrappedReference?: string) {
  request.setPageType(data.pageType)
  request.setRestoreLink(data.restoreLink)
  request.setProducts(data.products, unwrappedReference)

  if (data.elements) {
    request.addElements(data.elements)
  }
  request.setCartContent(data.cart)
  if (data.categories) {
    request.addCurrentCategories(data.categories)
  }
  if (data.categoryIds) {
    request.addCurrentCategoryIds(data.categoryIds)
  }
  if (data.parentCategoryIds) {
    request.addCurrentParentCategoryIds(data.parentCategoryIds)
  }
  if (data.customer) {
    request.addCustomer(data.customer)
  }
  if (data.tags) {
    request.addCurrentTags(data.tags)
  }
  if (data.customFields) {
    request.addCurrentCustomFields(data.customFields)
  }
  if (data.variation) {
    request.addCurrentVariation(data.variation)
  }
  if (data.order) {
    request.addOrderData(data.order as unknown as Order)
  }
  if (data.sortOrder) {
    request.setSortOrder(data.sortOrder)
  }
  if (data.affinitySignals) {
    request.setAffinitySignals(data.affinitySignals)
  }
  if (data.customer) {
    request.setCustomer(data.customer)
  }
  if (data.searchTerms) {
    data.searchTerms.forEach(term => {
      request.addEvent({ type: INTERNAL_SEARCH, target: term })
    })
  }
}

function populateEventsFromUrls(request: RequestBuilder, siteUrl: ParseUriResult, referer?: ParseUriResult) {
  const campaignEvent = extractFromUrl.campaignEvent(siteUrl)
  if (campaignEvent) {
    request.addEvent(campaignEvent)
  }

  const customCampaignEvent = extractFromUrl.isCustomCampaign(siteUrl, settings.segmentUrlParameters)
  if (customCampaignEvent) {
    request.addEvent({ type: CUSTOM_CAMPAIGN, target: customCampaignEvent })
  }

  const adWordEvent = extractFromUrl.adWordEvent(siteUrl)
  if (adWordEvent) {
    request.addEvent(adWordEvent)
  }

  if (referer) {
    const refererEvent = extractFromReferer.refererEvent(referer)
    if (refererEvent) {
      request.addEvent(refererEvent)
    }
  }

  const sourceEvent = extractFromUrl.sourceEvent(
    siteUrl,
    settings.sourceParameterName,
    settings.trackingTypes,
    settings.nostoRefParam
  )
  if (sourceEvent) {
    request.addEvent(sourceEvent)
  }
}

function populateReferences(request: RequestBuilder, siteUrl: ParseUriResult) {
  const recRef = extractFromUrl.recommendationRef(siteUrl, settings.nostoRefParam) || currentAttribution().ref
  const refMail = extractFromUrl.refMail(siteUrl)
  const recRefSrc = extractFromUrl.recommendationRef(siteUrl, REFERENCE_SOURCE) || currentAttribution().refSrc
  if (refMail) {
    request.setMailRef(refMail, recRef)
  } else if (recRef) {
    request.setRecommendationRef(recRef, recRefSrc)
  }
}

function normalizeFlags(flags?: RecommendationRequestFlags & { metadata?: boolean }) {
  if (flags && Object.keys(flags).length > 0) {
    // renames the legacy flag metadata to skipPageViews
    if ("metadata" in flags) {
      flags.skipPageViews = !!flags.metadata
    }
    // trackEvents is a convenience alias for skipEvents, which is inverted
    if ("trackEvents" in flags) {
      flags.skipEvents = !flags.trackEvents
    }
  }
}

function isEventTupleFormat(args: unknown[]): args is AddEventTuple {
  return typeof args[0] === "string" && isEventType(args[0]) && args.length <= 4
}

function getEventObject(args: IArguments) {
  if (isEventTupleFormat([...args])) {
    const [type, target, ref, targetFragment] = args
    return { type, target, ref, targetFragment }
  }
}

/**
 * Check if the data proxy is enabled by comparing the server url which
 * the script is being server from
 */
function isDataProxyEnabled() {
  const { server } = settings
  // TODO revisit this later, coupling to specific hardcoded URLs is not ideal
  return server.includes("staging.eu.nosto.com") || server.includes("connect.eu.nosto.com")
}

const piiFields = ["email", "first_name", "last_name"] as const

function removeCustomerPii<T extends Partial<OrderInfo>>(customerInfo: T): T {
  piiFields.forEach(prop => {
    if (prop in customerInfo) {
      delete customerInfo[prop]
    }
  })
  return customerInfo
}

function createRequestBuilder(baseState?: EventRequestMessageV1): RequestBuilder {
  let injectCampaigns = true

  const dataProxyEnabled = isDataProxyEnabled()

  let events: Event[] = []

  const state: EventRequestMessageV1 = {
    ...baseState,
    url: context.siteUrl?.href || undefined,
    response_mode: baseState?.response_mode || context.initOptions?.responseMode || "HTML",
    debug_token: context.debugToken || undefined,
    preview: context.mode.isPreview() || undefined,
    skipcache: context.mode.skipCache() || undefined,
    debug: getDebugRequest(),
    referrer: windows.site.document.referrer || undefined
  }

  if (context.mode.isRecotraceEnabled() && context.debugToken) {
    state.recotrace = context.debugToken
  }

  return {
    setForcedSegments(segments: string[]) {
      // not supported
      return this
    },

    setSegmentCodes(segments: string[]) {
      state.segment_codes = segments
      return this
    },

    setPageType(pageType: PageType | undefined) {
      state.page_type = pageType ? (pageType.toLowerCase() as PageType) : undefined
      return this
    },

    setSortOrder(sortOrder: string) {
      state.sort_order = sortOrder ? sortOrder.toLowerCase() : sortOrder
      return this
    },

    setAffinitySignals(signals) {
      state.affinity_signals = signals
      return this
    },

    addEvent(event: Event) {
      // eslint-disable-next-line prefer-rest-params
      const eventObject = getEventObject(arguments) ?? event
      events.push(cleanupEvent(eventObject))
      return this
    },

    setCustomer(customer: PushedCustomer) {
      if (dataProxyEnabled) {
        customer = removeCustomerPii(customer)
      }
      const def = Object.keys(customer).length ? { type: "loggedin" } : {}
      state.customer = { ...def, ...customer }
      return this
    },

    setCoupon({ campaign, code, used }: Coupon) {
      state.coupon_campaign = campaign || undefined
      state.coupon_code = code || undefined
      state.coupon_used = used || undefined
      return this
    },

    getEvents() {
      // XXX direct copy needed in shopify/sku-selection-listener.ts
      return events
    },

    getData() {
      return deepClone(state)
    },

    addElements(elements: string[]) {
      state.elements = [...(state.elements || []), ...elements]
      return this
    },

    setElements(elements: string[] | undefined) {
      state.elements = elements
      return this
    },

    setCartContent(cart: Cart | undefined) {
      state.cart_hash = cart?.hcid
      state.cart = cart?.items
      return this
    },

    setRestoreLink(restoreLink: string | undefined) {
      state.restore_link = restoreLink
      return this
    },

    addCartItems() {
      // No-Op
      return this
    },

    addCartCookieHash(hcid: string) {
      state.cart_hash = hcid
      return this
    },

    addCartTotal() {
      // No-Op
      return this
    },

    addCartSize() {
      // No-Op
      return this
    },

    setProducts(products: Product[], ref?: string) {
      events = events.filter(event => event.type !== VIEW_PRODUCT)

      if (products) {
        toVpEvents(products, ref).forEach(event => events.push(cleanupEvent(event)))
      }
      return this
    },

    addCurrentCategories(categories: string[]) {
      state.categories = [...(state.categories || []), ...categories]
      return this
    },

    setCurrentCategories(categories: string[]) {
      state.categories = categories
      return this
    },

    addCurrentCategoryIds(categoryIds: string[]) {
      state.category_ids = [...(state.category_ids || []), ...categoryIds]
      return this
    },

    addCurrentParentCategoryIds(parentCategoryIds: string[]) {
      // not supported
      return this
    },

    addCurrentTags(tags: string[]) {
      state.tags = [...(state.tags || []), ...tags]
      return this
    },

    setCurrentTags(tags: string[]) {
      state.tags = tags
      return this
    },

    addCurrentCustomFields(fields: Record<string, string[]>) {
      state.custom_fields = { ...(state.custom_fields || {}), ...fields }
      return this
    },

    setCurrentPriceFrom(value: number) {
      // not supported
      return this
    },

    setCurrentPriceTo(value: number) {
      // not supported
      return this
    },

    addCurrentVariation(variation: string) {
      state.current_variant_id = variation
      return this
    },

    addCustomer(customer: PushedCustomer) {
      return this.setCustomer(customer)
    },

    setResponseMode(mode: RenderMode) {
      state.response_mode = mode
      return this
    },

    setExperiments(experiments: Experiment[]) {
      state.experiments = experiments
      return this
    },

    disableCampaignInjection() {
      injectCampaigns = false
      return this
    },

    enablePreview() {
      state.preview = true
      return this
    },

    addOrderData({ items }: Order) {
      if (items) {
        toBpEvents(items).forEach(event => events.push(cleanupEvent(event)))
      }
      return this
    },

    // noinspection JSUnusedGlobalSymbols
    setMailRef(id: string, target: string) {
      if (id && (id.indexOf("$") === 0 || id.indexOf(".") > -1)) {
        throw new Error(`Illegal reference ${id}`)
      }
      state.mail_ref = id
      state.mail_type = target
      return this
    },

    populateFrom(params: { data: TaggingData; forcedSegments: string[] }, unwrappedReference?: string) {
      populateFromData(this, params.data, unwrappedReference)
      populateEventsFromUrls(this, context.siteUrl, context.referer)
      if (!context.mode.isPreview()) {
        populateReferences(this, context.siteUrl)
      }
      state.external_identifier = externalIdentification() || undefined
      state.klaviyo_cookie = klaviyoCookie() || undefined
      return this
    },

    setRecommendationRef(recRef: string, recRefSrc?: string) {
      let refSet = false
      events
        .filter(px => px.type === VIEW_PRODUCT)
        .forEach(px => {
          // event array order: [type, target, ref, refSrc, targetFragment, refType]
          px.ref = recRef
          refSet = true
          if (recRefSrc) {
            px.refSrc = recRefSrc
          }
        })

      if (!refSet) {
        state.ref = recRef
      }
      return this
    },

    send(flags: RecommendationRequestFlags) {
      return this.load(flags)
    },

    load(flags?: RecommendationRequestFlags) {
      normalizeFlags(flags)
      return measurePerformance("nosto.load_recommendations", async () => {
        if (events.length) {
          // provide deterministic event order indepdent of insertion order
          events.sort((e1, e2) => -e1.type.localeCompare(e2.type))
          state.events = toEventTuples(validateEvents(events))
        }
        bus.emit("prerequest", state)
        try {
          const response = await measurePerformance("nosto.ev1", () => ev1(state, flags))
          const customerId = response.headers[X_NOSTO_CUSTOMER_HEADER]
          if (customerId) {
            try {
              visit.setCustomerId(customerId)
            } catch (e) {
              logger.warn("Error setting customer id", e)
            }
          }
          const responseData = response.data as EventResponseMessage
          handleResponse(responseData, state.response_mode === "HTML" && injectCampaigns)
          bus.emit("taggingsent", responseData)
          return responseData
        } catch (err) {
          bus.emit("servererror", parseError(err) ?? [])
          throw err
        } finally {
          bus.emit("ev1end")
        }
      })
    },

    loadCartPopupRecommendations(alwaysShow: boolean) {
      // noinspection JSDeprecatedSymbols
      state.cart_popup = alwaysShow
      return this.load({ skipPageViews: true })
    },

    loadRecommendations(flags?: RecommendationRequestFlags) {
      return this.load(flags)
    },

    setRefs(refs: Record<string, string>) {
      // add missing events
      Object.keys(refs)
        .filter(id => !events.some(ev => ev.target === id))
        .forEach(id => this.addEvent({ type: VIEW_PRODUCT, target: id }))

      // set event attribution
      events.filter(ev => refs[ev.target!]).forEach(ev => (ev.ref = refs[ev.target!]))

      return this
    }
  }
}

export default createRequestBuilder

/**
 * RequestBuilder is a low level API to interact with the Nosto API. It's primary purpose is to fetch recommendations
 * and send events to the Nosto API. It is not recommended to use this API directly, but rather use the the higher level
 * Tagging and Session APIs.
 *
 * @group Core
 */
export interface RequestBuilder {
  /**
   * Sets the given list of forced segment identifiers to the current request. This
   * method allows you explicitly associate the current customer with a segment.
   *
   * @private
   * @param {String[]} segments the list of force segment identifiers
   */
  setForcedSegments(segments: string[]): RequestBuilder
  /**
   * Sets the given list of manual segment identifiers to the current request. This
   * method allows you explicitly associate the current customer with a segment.
   *
   * @param {String[]} segments the list of force segment identifiers
   */
  setSegmentCodes(segments: string[]): RequestBuilder
  /**
   * Sets the identifier of the current page type to the current request. The different
   * page types are product, front, search, cart, order, category, notfound and other.
   *
   * @param {String} pageType the current page type
   */
  setPageType(pageType: PageType | undefined): RequestBuilder
  /**
   * Sets the identifier of the current sort order to the current request.
   *
   * @private
   * @param {String} sortOrder the current sort order
   */
  setSortOrder(sortOrder: string): RequestBuilder
  /**
   * Adds affinity signals to the current request.
   *
   * @param signals signals to set
   */
  setAffinitySignals(signals: Record<string, string[]>): RequestBuilder
  /**
   * Adds the given event to the current set of events. When viewing a product,
   * it is required that you specify the "vp" as event and the product id as
   * the target.
   *
   * Also supports legacy signature
   * addEvent(type: string, target?: string, ref?: string, targetFragment?: string) {
   * }
   *
   * @private
   * @param {Event} event { type, target, ref, refSrc, targetFragment, refType }
   */
  addEvent(event: Event): RequestBuilder
  /**
   * Sets the information about the currently logged in customer. If the current
   * customer is not provided, you will not be able to leverage features such as
   * triggered emails. While it is recommended to always provide the details of
   * the currently logged in customer, it may be omitted if there are concerns
   * about privacy or compliance.
   * <br/><br/>
   * It is not recommended to pass the current customer details to the request
   * builder but rather use the customer tagging.
   *
   * @param {Customer} customer the details of the currently logged in customer
   */
  setCustomer(customer: PushedCustomer): RequestBuilder
  setCoupon(coupon: Coupon): RequestBuilder
  /**
   * Adds the given elements (or placements) to the request. Any identifiers
   * specified here are simply added to the elements already in the request.
   *
   * @param {String[]} elements the array of placements
   *
   * @example
   * <caption>To load data for a single placement</caption>
   * nostojs(api => api
   *   .createRecommendationRequest()
   *   .addElements(['bestseller-home'])
   *   .loadRecommendations());
   */
  addElements(elements: string[]): RequestBuilder
  /**
   * Sets the given elements (or placements) to the request. Any identifiers
   * specified here override all elements already in the request.
   *
   * @param {String[]} elements the array of placements
   *
   * @example
   * <caption>To load data for a single placement</caption>
   * nostojs(api => api
   *   .createRecommendationRequest()
   *   .setElements(['bestseller-home'])
   *   .loadRecommendations());
   */
  setElements(elements: string[] | undefined): RequestBuilder
  /**
   * Adds the cart object to the current request. This should be preferably
   * on every page load so as to keep the cart state as fresh as possible.
   *
   * @param {PushedCart} cart the details of the current shopping basket
   */
  setCartContent(cart: Cart | undefined): RequestBuilder
  /**
   * Sets the restore link for the current session. Restore links can be leveraged
   * in email campaigns. Restore links allow the the user to restore the cart
   * contents in a single click.
   * <br/><br/>
   * Read more about
   * {@link https://help.nosto.com/en/articles/664692|how to leverage the restore cart link}
   * <br/><br/>
   * It is not recommended to pass the current restore link to the request
   * builder but rather use the tagging approach.
   *
   * @param restoreLink
   */
  setRestoreLink(restoreLink: string | undefined): RequestBuilder
  /**
   * Adds the given identifiers of the products in the customer's shopping cart
   * to the request.
   *
   * @deprecated since this only transported partial information about the cart
   * @private
   */
  addCartItems(): RequestBuilder
  /**
   * Sets the hash of the current cart cookie for ensure that the cart tagging
   * isn't cached. In most cases, simply reading the customer's 2c.cid cookie
   * and generating a SHA256 checksum will suffice.
   *
   * @deprecated
   * @param {String} hcid the 32 character unique hash
   */
  addCartCookieHash(hash: string): RequestBuilder
  /**
   * Sets the total value of the customer's shopping cart. This should be the
   * numerical value of what the customer sees in the mini-cart element of the
   * store.
   *
   * @deprecated since this only transported partial information about the cart
   * @private
   */
  addCartTotal(): RequestBuilder
  /**
   * Sets the total value of the customer's shopping cart. This should be the
   * numerical value of what the customer sees in the mini-cart element of the
   * store.
   *
   * @deprecated since this only transported partial information about the cart
   * @private
   */
  addCartSize(): RequestBuilder
  /**
   * @param {Array.<Product>} products
   * @param {String} [ref] the placement id that resulted in the product views
   */
  setProducts(products: Product[], ref?: string): RequestBuilder
  /**
   * Adds the given category names to the request. Any category name specified here
   * are simply added to the request as personalisation filtering hints.
   *
   * @param {String[]} categories the array of category ids
   */
  addCurrentCategories(categories: string[]): RequestBuilder
  /**
   * Sets the given category names to the request. Any category names specified here
   * override the category names in the request.
   *
   * @param {String[]} categories the array of category ids
   */
  setCurrentCategories(categories: string[]): RequestBuilder
  /**
   * Adds the given category ids to the request. Any category ids specified here
   * are simply added to the request as personalisation filtering hints.
   *
   * @param {String[]} categoryIds the array of category ids
   */
  addCurrentCategoryIds(categoryIds: string[]): RequestBuilder
  /**
   * Adds the given parent category ids to the request. Any parent category ids specified here
   * are simply added to the request as personalisation filtering hints.
   */
  addCurrentParentCategoryIds(parentCategoryIds: string[]): RequestBuilder
  /**
   * Adds the given current tags to the request. Any tags (tags1, tags12, or
   * tags13) specified here are simply added to the request as personalisation
   * filtering hints.
   *
   * @param {String[]} tags the array of tags
   */
  addCurrentTags(tags: string[]): RequestBuilder
  /**
   * Sets the given current tags to the request. Any tags (tags1, tags12, or
   * tags13) specified here are simply set to the request as personalisation
   * filtering hints.
   *
   * @param {String[]} tags the array of tags
   */
  setCurrentTags(tags: string[]): RequestBuilder
  /**
   * Adds the given current custom fields to the request. Any custom fields
   * specified here are simply added to the request as personalisation filtering hints.
   *
   * @param { Object } fields custom field key-value pairs
   */
  addCurrentCustomFields(fields: Record<string, string[]>): RequestBuilder
  /**
   * Sets the current lower price range to the request. Faceting needs to be
   * enabled for the slot in order for this to function. Any lower value
   * specified here are simply added to the request as personalisation filtering hints.
   *
   * @param {Number} value the lower range of the price
   */
  setCurrentPriceFrom(value: number): RequestBuilder
  /**
   * Sets the current upper price range to the request. Faceting needs to be
   * enabled for the slot in order for this to function. Any upper value
   * specified here are simply added to the request as personalisation filtering hints.
   *
   * @param {Number} value the upper range of the price
   */
  setCurrentPriceTo(value: number): RequestBuilder
  /**
   * Sets the current variation identifier for the session. A variation identifier
   * identifies the current currency (or the current customer group). If your site
   * uses multi-currency, you must provide the ISO code current currency being viewed.
   * <br/><br/>
   * It is not recommended to pass the variation identifier to an request builder but
   * instead leverage the tagging.
   *
   * @param {String} variation the case-sensitive identifier of the current variation
   */
  addCurrentVariation(variation: string): RequestBuilder
  /**
   * Sets the information about the currently logged in customer. If the current
   * customer is not provided, you will not be able to leverage features such as
   * triggered emails. While it is recommended to always provide the details of
   * the currently logged in customer, it may be omitted if there are concerns
   * about privacy or compliance.
   * <br/><br/>
   * It is not recommended to pass the current customer details to the request
   * builder but rather use the customer tagging.
   *
   * @param {Customer} customer
   */
  addCustomer(customer: PushedCustomer): RequestBuilder
  /**
   * Sets the response mode for the current request. The response mode can be
   * used to switch between HTML and JSON. Here is an exhaustive list of the
   * response modes.
   *
   * | Modes                | Description                                    |
   * | -------------------- |:----------------------------------------------:|
   * | HTML                 | HTML (SSR)                                     |
   * | JSON_170x170         | Raw JSON with 170x170px original aspect images |
   * | JSON_100_X_100       | Raw JSON with 100x100px original aspect images |
   * | JSON_90x70           | Raw JSON with 90x70px original aspect images   |
   * | JSON_50x50           | Raw JSON with 50x50px original aspect images   |
   * | JSON_30x30           | Raw JSON with 30x30px original aspect images   |
   * | JSON_100x140         | Raw JSON with 100x140px original aspect images |
   * | JSON_200x200         | Raw JSON with 200x200px original aspect images |
   * | JSON_400x400         | Raw JSON with 400x400px original aspect images |
   * | JSON_750x750         | Raw JSON with 750x750px original aspect images |
   * | JSON_10_MAX_SQUARE   | Raw JSON with 100x100px center squared images  |
   * | JSON_200x200_SQUARE  | Raw JSON with 200x200px center squared images  |
   * | JSON_400x400_SQUARE  | Raw JSON with 400x400px center squared images  |
   * | JSON_750x750_SQUARE  | Raw JSON with 750x750px center squared images  |
   * | JSON_ORIGINAL        | Raw JSON with the original untouched images    |
   *
   * @param {String} mode the response mode to be used
   */
  setResponseMode(mode: RenderMode): RequestBuilder
  setExperiments(experiments: Experiment[]): RequestBuilder
  disableCampaignInjection(): RequestBuilder
  /**
   * Enables the preview mode for the current request. The preview mode is
   * automatically gathered from the current context's preview mode. If the
   * debug toolbar is showing and preview mode is enabled, there is no need
   * to invoke this function.
   */
  enablePreview(): RequestBuilder
  /**
   * Adds the order object to the current request. This should be invoked only
   * on the order confirmation page.
   *
   * @param {Order} order the details of the order that was placed
   */
  addOrderData(order: Order): RequestBuilder
  setMailRef(refMail: string, recRef: string): RequestBuilder
  populateFrom(params: { data: TaggingData; forcedSegments: string[] }, unwrappedReference?: string): RequestBuilder
  setRecommendationRef(recRef: string, recRefSrc?: string): RequestBuilder
  /**
   * Builds the request and makes a request to Nosto.
   *
   * @deprecated since there is already a load method that does the same
   * @private
   * @param {RecommendationRequestFlags} flags
   * @return {Promise}
   *
   * @example
   * <caption>To load data for a single placement</caption>
   * nostojs(api => api
   *   .createRecommendationRequest()
   *   .send({metadata: true}))
   *   .then((response) => console.log(response));
   */
  send(flags: RecommendationRequestFlags): Promise<EventResponseMessage>
  /**
   * Builds the request and makes a request to Nosto.
   *
   * @param {RecommendationRequestFlags} flags an object containing additional flags
   * @return {Promise} the response returned by Nosto
   *
   * @example
   * <caption>To load data for a single placement</caption>
   * nostojs(api => api
   *   .createRecommendationRequest()
   *   .addElements('bestseller-home')
   *   .load())
   *   .then((response) => console.log(response));
   */
  load(flags?: RecommendationRequestFlags): Promise<EventResponseMessage>
  /**
   * Builds the request and invokes it via JSONP to load the cart popup
   * recommendations
   *
   * @deprecated since this method should de decoupled from the request
   * @private
   * @return {Promise}
   */
  loadCartPopupRecommendations(alwaysShow: boolean): Promise<EventResponseMessage>
  /**
   * Legacy method used to reloading the recommendations. This method is a
   * simple wrapper around the other load method.
   *
   * @deprecated since the method name isn't aligned with it's behaviour
   * @private
   * @see {load}
   * @param {RecommendationRequestFlags} flags an object containing additional flags
   * @return {Promise}
   *
   * @example
   * <caption>To load data for a single placement</caption>
   * nostojs(api => api
   *   .createRecommendationRequest()
   *   .addElements('bestseller-home')
   *   .loadRecommendations())
   *   .then((response) => console.log(response));
   */
  loadRecommendations(flags?: RecommendationRequestFlags): Promise<EventResponseMessage>
  /**
   * Adds attribution for the given product id to reference mappings
   *
   * @param refs
   * @returns
   */
  setRefs(refs: Record<string, string>): RequestBuilder
  getEvents(): Event[]
  getData(): EventRequestMessageV1
}
